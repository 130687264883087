import React from 'react';
import packageJson from '../../../package.json';
import SCJSS from '../../temp/config';

class Context{

    static getIndexName(args) {
        return "sitecore_" + args.sitecoreContext.route.databaseName + "_" + packageJson.config.appName.split('-').join( '_') + "_index";
        //return "sitecore_master_jss_master_index";
    }

    static getGraphQLEndPoint(hostParam){
		if (this.isEs(hostParam)) {
			return SCJSS.custom_graphQLEndpointES;
		}
        return SCJSS.graphQLEndpoint;
    }

    static getAppName(hostParam) {
        return packageJson.config.appName;
    }

    static getUrlSer(hostParam) {
		if (this.isEs(hostParam)) {
			return SCJSS.custom_sitecoreApiHostES;
		}
        return SCJSS.sitecoreApiHost;
    }
	
	static isEs(hostParam) {
		if (hostParam !== null && SCJSS.custom_domainES !== null) {
		    var urls = SCJSS.custom_domainES.split('|');
            for (var item in urls) {
                if (hostParam.indexOf(urls[item]) != -1) {
		            return true;
	            }
            }    
	    }
		
        return false;
    }

}

export default Context;
