import React from "react";
import {
  Image,
  isExperienceEditorActive,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import "./productImagesGalleryV2.scss";
import { DotLoader } from 'react-spinners';
import Zoom from 'react-img-zoom'


class ProductImagesGalleryV2 extends React.Component {

  state = {
    activeImage: 0,
    baseDimension: 647,
    isLoaded: false
  }

  componentDidMount() {
    this.setState({isLoaded: true})

    window.addEventListener('resize', this.resizeGallery)
    setTimeout(this.resizeGallery ,500)

  }

  resizeGallery = () => {

    if(document.querySelector(".image-container")){
      const imgCtnElem = document.querySelector(".image-container")

      imgCtnElem.style.width = window.innerWidth <= 768 ? "100%": "80%"
      imgCtnElem.style.height = "520px"
      const imgElements = document.querySelectorAll(".image-container .img div")
      const newDimension = imgCtnElem.offsetWidth -1
      
      imgCtnElem.style.width = newDimension + "px"
      imgCtnElem.style.height = newDimension + "px"
      imgElements.forEach(function(item){
        item.style.width = newDimension + "px"
        item.style.height = newDimension + "px"
      })

      this.setState({baseDimension: newDimension})
      
    }


  }

  handleImageChange(index) {
    this.setState({activeImage: index})
  }


  render() {
      
      const product = this.props.sitecoreContext.route.fields;
      const { isLoaded, activeImage, baseDimension } = this.state;

      const images = [];
      const imageItems = Object.keys(product).filter(key => key.includes("image")).sort()
      imageItems.forEach(function(key){
        if(product[key].value.src){
          images.push(product[key])
        }
      })
     
      if(!isLoaded) {
        return(     
          <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
            <DotLoader sizeUnit={"px"} size={50} color={'#0057b4'} loading={!isLoaded}/>
          </div>          
        );
      }else{
        return(
            <div className="single-product-images">
              <div className="product-thumbnail">
                {images.map((img, index)=>{
                    return <Image loading="lazy"  onClick={()=>this.handleImageChange(index)}
                                  onMouseOver={()=>this.handleImageChange(index)} 
                                  key={index} 
                                  field={img} 
                                  className={activeImage===index?"thumb active":"thumb"}
                            />
                  })}
              </div>
              
              {!isExperienceEditorActive() && (
                <div className="image-container" >
                  {images.map((img, index)=>{
                    return (
                    <div className={activeImage===index?"img active":"img"} key={index + Math.random()}>
                      <Zoom
                        img={img.value.src}
                        zoomScale={2}
                        width={baseDimension}
                        height={baseDimension}
                        transitionTime={0}
                      />
                    </div>
                    )
                  })}
                </div>
              )}
        </div>
      )}
    
    }

}

export default withSitecoreContext()(ProductImagesGalleryV2);
