import React from 'react';
import { Text, Image, RichText, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';
import './featuredPosts.scss';
import ReactComponent from '../../ReactComponent';
import { handleSkindrLaunch } from '../Tagging';

class FeaturedPosts extends ReactComponent {
    componentDidMount() {
        if (this.props.sitecoreContext.route.name === 'Diagnostic Peau') this.loadDropper();
    }

    componentWillUnmount() {
        if (document.querySelector('#dropper-script')) {
            document.querySelector('#dropper-script').remove();
            document.querySelector('#dsf-element').remove();
        }
    }

    loadDropper() {
        var url = new URL(window.location.href);
        let mydiv = document.createElement('div');
        mydiv.innerHTML = `
				<dsf-app
        id="dsf-element"
				tenant = "${url.searchParams.get('tenant') || 'lorealsa'}"
				application = "${url.searchParams.get('application') || 'skindrv2'}"
				customer = "${url.searchParams.get('customer') || 'mixa'}"
				country = "${url.searchParams.get('country') || 'fra'}"
				environment = "${url.searchParams.get('environment') || 'production'}"
				touchpoint = "${url.searchParams.get('touchpoint') || 'web'}"
				type = "${url.searchParams.get('type') || 'std'}"
				customer-embed = "false"
				loadingbg = "#000"
				loadingmsg = "loading"
				csrf_token = "$WEBSITE_TOKEN"
				switch_ecom = "true"
				switch_autostart = "true"
			/>`;

        if (!document.querySelector('#dsf-element')) {
            document.body.appendChild(mydiv);
            window.dsfApp = document.createElement('div');
            window.dsfApp.registerButtons = function () {};

            async function loadScripts(script_urls) {
                function load(script_url) {
                    return new Promise(function (resolve, reject) {
                        if (loadScripts.loaded.has(script_url)) {
                            resolve();
                        } else {
                            var script = document.createElement('script');
                            script.onload = resolve;
                            script.src = script_url;
                            script.id = 'dropper-script';
                            document.head.appendChild(script);
                        }
                    });
                }
                var promises = [];
                for (const script_url of script_urls) {
                    promises.push(load(script_url));
                }
                await Promise.all(promises);
                for (const script_url of script_urls) {
                    loadScripts.loaded.add(script_url);
                }
            }
            loadScripts.loaded = new Set();

            (async () => {
                await loadScripts([
                    'https://dsf-cdn-staging.loreal.io/dropper.js' + '?v=' + getRandomInt(1000),
                ]);

                window.dsfApp.registerButtons();
                window.dsfApp.addEventListener('onLoadComplete', (e) => {
                    console.log(`${e.detail.application} loaded`);
                });
                window.dsfApp.addEventListener('onReady', (e) => {
                    console.log('ready');
                });
            })();

            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }
        }
    }

    render() {
        const datasource = this.props.fields || {};

        if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            const { FeaturedPostsContentList, designVariant } = this.props.fields;

            const Title = (listItem) => {
                return <Text field={listItem} tag={'h3'} />;
            };

            return (
                <React.Fragment>
                    <div className={'section featuredPosts ' + designVariant.value}>
                        <div className="first-part">
                            {designVariant.value === 'centerWhite' && (
                                <div className="col-md-12 container-diag">
                                    <div
                                        onClick={() => handleSkindrLaunch()}
                                        className="diagnostic-btn button-poppin button-open-dsf-app"
                                        opencolor="#d7ac42"
                                    >
                                        {i18next.t('diagnotic-btn')}
                                    </div>
                                    <div id="dsf-container"></div>
                                </div>
                            )}
                            <div className="row">
                                <div className="contentTitle">
                                    <Image
                                        loading="lazy"
                                        field={this.props.fields && this.props.fields.Logo}
                                    />
                                    <Text
                                        field={this.props.fields && this.props.fields.Title}
                                        tag={'h2'}
                                        className={'title'}
                                    />
                                    <Text
                                        field={this.props.fields && this.props.fields.SubTitle}
                                        tag={'span'}
                                        className={'subTitle'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="second-part">
                            <div className="row">
                                {FeaturedPostsContentList &&
                                    FeaturedPostsContentList.map((listItem, index) => (
                                        <div className="contents" key={`sharedListItem-${index}`}>
                                            <NavLinkAdv
                                                to={
                                                    listItem.fields.PostLink &&
                                                    listItem.fields.PostLink.value.href
                                                }
                                            >
                                                {!designVariant.value &&
                                                    Title(listItem.fields.title)}
                                                <div className="text-center">
                                                    <Image
                                                        loading="lazy"
                                                        loading="lazy"
                                                        field={listItem.fields.Icon}
                                                    />
                                                </div>
                                                {designVariant.value &&
                                                    Title(listItem.fields.title)}
                                                <RichText
                                                    field={listItem.fields.content}
                                                    tag={'div'}
                                                    className={'content'}
                                                />
                                            </NavLinkAdv>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="component-no-datasource">
                    {i18next.t('no-datasource') +
                        ' ' +
                        this.props.rendering.componentName +
                        ' ' +
                        i18next.t('component')}
                </div>
            );
        }
    }
}
export default withSitecoreContext()(FeaturedPosts);
