import React from 'react';
import { Text, Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import './featuredPostsDynamic.scss';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

const FeaturedPostsDynamicQuery = gqlLoader('./FeaturedPostsDynamicQuery.graphql');

class FeaturedPostsDynamic extends ReactComponent {
    render() {
        const datasource = this.props.fields || {};
        if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            const { FeaturedPostsContentList } = this.props.fields;
            const designVariant =
                typeof this.props.fields.designVariant === 'undefined'
                    ? ''
                    : this.props.fields.designVariant.value;

            return (
                <div className={'FeaturedPostsDynamic ' + designVariant}>
                    <div className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h2 className="text-center title">
                                        <Text field={this.props.fields.Title} />
                                    </h2>
                                    <p className="text-center subTitle">
                                        <Text field={this.props.fields.Text} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            {FeaturedPostsContentList &&
                                FeaturedPostsContentList.map((listItem, index) => (
                                    <Query
                                        query={FeaturedPostsDynamicQuery}
                                        variables={{
                                            indexname: this.indexName,
                                            rootPath: 'sitecore/content/' + this.appName + '/',
                                            id: listItem.id.split('-').join(''),
                                        }}
                                        key={index}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading)
                                                return (
                                                    <div className="section mt-2 pb-4">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div
                                                                    className="sweet-loading"
                                                                    style={{
                                                                        marginTop: '50px',
                                                                        marginBottom: '50px',
                                                                    }}
                                                                >
                                                                    <DotLoader
                                                                        sizeUnit={'px'}
                                                                        size={50}
                                                                        color={'#0057b4'}
                                                                        loading={loading}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            if (error)
                                                return (
                                                    <div className="section mt-2 pb-4">
                                                        <div className="container">
                                                            <div className="row">
                                                                Error: {error.message}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            return (
                                                data.customSearch.results &&
                                                data.customSearch.results.items.map((item, idx) => {
                                                    if (
                                                        listItem.id
                                                            .split('-')
                                                            .join('')
                                                            .toUpperCase() === item.item.id
                                                    ) {
                                                        return (
                                                            <div
                                                                className="col-sm-12 col-md-4 col-xl-4 items"
                                                                key={`sharedListItem-${idx}`}
                                                            >
                                                                <NavLinkAdv
                                                                    to={item.item && item.item.url}
                                                                >
                                                                    <div className="text-center item-img">
                                                                        <Image
                                                                            loading="lazy"
                                                                            field={
                                                                                listItem.fields
                                                                                    .PostThumbnailImageMedium ||
                                                                                listItem.fields
                                                                                    .articleImage
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <h4 className="text-center mt-2 mb-2 articleTitle">
                                                                        <Text
                                                                            field={
                                                                                listItem.fields
                                                                                    .PostTitle ||
                                                                                listItem.fields
                                                                                    .articleTitle
                                                                            }
                                                                        />
                                                                    </h4>
                                                                    <div className="text-center item-content">
                                                                        <RichText
                                                                            field={
                                                                                listItem.fields
                                                                                    .PostShortDescription ||
                                                                                listItem.fields
                                                                                    .articleContent
                                                                            }
                                                                        />
                                                                    </div>
                                                                </NavLinkAdv>
                                                            </div>
                                                        );
                                                    }
                                                    return false;
                                                })
                                            );
                                        }}
                                    </Query>
                                ))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="component-no-datasource">
                    {i18next.t('no-datasource') +
                        ' ' +
                        this.props.rendering.componentName +
                        ' ' +
                        i18next.t('component')}
                </div>
            );
        }
    }
}

export default withSitecoreContext()(FeaturedPostsDynamic);
