import React from 'react';
import i18next from 'i18next';
import './RatingReviewsFilter.scss';
import moment from 'moment';



class RatingReviewsFilter extends React.Component {
    starsTrad = i18next.t('rr-stars');
    // console.log(i18next.t("Acheter")); 

    sortOrder = [
        ['rates', i18next.t('rr-rates')],
        ['high', i18next.t('rr-high')],
        ['low', i18next.t('rr-low')],
    ];

    dateOrder = [
        ['dates', i18next.t('rr-dates')],
        ['new', i18next.t('rr-new')],
        ['old', i18next.t('rr-old')],
    ];

    filters = [
        ['allScores', i18next.t('rr-allScores')],
        ['5', '5 '+this.starsTrad],
        ['4', '4 '+this.starsTrad],
        ['3', '3 '+this.starsTrad],
        ['2', '2 '+this.starsTrad],
        ['1', '1 '+this.starsTrad],
    ];

    cumulableFilter = [];
    newDataFiltered;
    firstPropsComment;

    state = {
        sortingOrder: this.sortOrder[0][0],
        sortingDateOrder: this.dateOrder[0][0],
        scoresFilter: this.filters[0][0],
        isScoreActive: false,
        isRateActive: false,
        isDateActive: false,
        isRateForScore: false,
    };

    

    componentDidMount() {
        this.firstPropsComment = this.props.comments.sort((a, b) => {
            return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
        });
        this.props.onFilterChange(this.firstPropsComment);
    }

    // Functions to filter by Score
    changeFilterOption = (e, filterType) => {
        let valueOption = e.currentTarget.value;
        this.handleFilters(valueOption, filterType);

        if (valueOption === '5') {
            this.newDataFiltered = this.firstPropsComment.filter((itm) => itm.item.rating.numberValue === 5);
        } else if (valueOption === '4') {
            this.newDataFiltered = this.firstPropsComment.filter((itm) => itm.item.rating.numberValue === 4);
        } else if (valueOption === '3') {
            this.newDataFiltered = this.firstPropsComment.filter((itm) => itm.item.rating.numberValue === 3);
        } else if (valueOption === '2') {
            this.newDataFiltered = this.firstPropsComment.filter((itm) => itm.item.rating.numberValue === 2);
        } else if (valueOption === '1') {
            this.newDataFiltered = this.firstPropsComment.filter((itm) => itm.item.rating.numberValue === 1);
        } else {
            if (this.cumulableFilter.length > 0) {
                this.cumulableFilter.map((filter) => {
                    if (filter.filterType === 'sortingDateOrder') {
                        this.changeSortOption('', filter.filterType, filter.selectedOption);
                    }
                });
            } else {
                this.newDataFiltered = this.firstPropsComment;
            }
        }

        this.props.onFilterChange(this.newDataFiltered);
    };

    scoreActive(value) {
        this.setState({
            isScoreActive: value,
        });
    }

    // Functions to sort Order / Date
    changeSortOption = (e, filterType, isScore) => {
        let valueOption;
        if (e) {
            valueOption = e.currentTarget.value;
        }
        if (valueOption) {
            this.handleFilters(valueOption, filterType);
        }

        if (filterType === 'sortingOrder') {
            if (valueOption === 'high' || isScore === 'high') {
                this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                    return b.item.rating.numberValue - a.item.rating.numberValue;
                });
                this.setState({
                    isRateActive: true,
                    isRateForScore: true,
                });
            } else if (valueOption === 'low' || isScore === 'low') {
                this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                    return a.item.rating.numberValue - b.item.rating.numberValue;
                });
                this.setState({
                    isRateActive: true,
                    isRateForScore: true,
                });
            } else if (valueOption === 'rates' || isScore === 'rates') {
                this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                    return (
                        moment(b.item.date.value).format('X') -
                        moment(a.item.date.value).format('X')
                    );
                });
                this.setState({
                    isRateActive: false,
                    isRateForScore: false,
                });
            }
        } else if (filterType === 'sortingDateOrder') {
            if (this.newDataFiltered) {
                if (valueOption === 'new' || isScore === 'new') {
                    if (isScore) {
                        this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                            return (
                                moment(b.item.date.value).format('X') -
                                moment(a.item.date.value).format('X')
                            );
                        });
                    } else {
                        this.newDataFiltered = this.newDataFiltered.sort((a, b) => {
                            return (
                                moment(b.item.date.value).format('X') -
                                moment(a.item.date.value).format('X')
                            );
                        });
                    }
                    this.setState({
                        isDateActive: true,
                    });
                } else if (valueOption === 'old' || isScore === 'old') {
                    if (isScore) {
                        this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                            return (
                                moment(a.item.date.value).format('X') -
                                moment(b.item.date.value).format('X')
                            );
                        });
                    } else {
                        this.newDataFiltered = this.newDataFiltered.sort((a, b) => {
                            return (
                                moment(a.item.date.value).format('X') -
                                moment(b.item.date.value).format('X')
                            );
                        });
                    }
                    this.setState({
                        isDateActive: true,
                    });
                } else if (valueOption === 'dates' || isScore === 'dates') {
                    this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                        return (
                            moment(b.item.date.value).format('X') -
                            moment(a.item.date.value).format('X')
                        );
                    });
                    this.setState({
                        isDateActive: false,
                    });
                }
            } else {
                if (valueOption === 'new' || isScore === 'new') {
                    this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                        return (
                            moment(b.item.date.value).format('X') -
                            moment(a.item.date.value).format('X')
                        );
                    });
                    this.setState({
                        isDateActive: true,
                    });
                } else if (valueOption === 'old' || isScore === 'old') {
                    this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                        return (
                            moment(a.item.date.value).format('X') -
                            moment(b.item.date.value).format('X')
                        );
                    });
                    this.setState({
                        isDateActive: true,
                    });
                } else if (valueOption === 'dates' || isScore === 'dates') {
                    this.newDataFiltered = this.firstPropsComment.sort((a, b) => {
                        return (
                            moment(b.item.date.value).format('X') -
                            moment(a.item.date.value).format('X')
                        );
                    });
                    this.setState({
                        isDateActive: false,
                    });
                }
            }
        }

        this.props.onFilterChange(this.newDataFiltered);
    };

    /*
        Add or Remove selectedOption in the tab cumulableFilter
        @selectedOption: value of the option
        @filtlerType: name of the filter
    */
    handleFilters(selectedOption, filterType) {
        if (this.cumulableFilter.length !== 0) {
            let indexOfItem = this.cumulableFilter.findIndex((obj) => obj.filterType === filterType);
            let isOrderAndScore = this.cumulableFilter.some((e) => e.filterType === 'sortingOrder');

            // Find if the new item is already in the array
            if (this.cumulableFilter.some((e) => e.filterType === filterType)) {
                if (
                    selectedOption === 'allScores' ||
                    selectedOption === 'rates' ||
                    selectedOption === 'dates'
                ) {
                    this.cumulableFilter.splice(indexOfItem, 1);
                    if (filterType === 'scoresFilter' && selectedOption === 'allScores') {
                        this.scoreActive(false);
                    }
                } else {
                    if (filterType === 'scoresFilter' && isOrderAndScore) {
                        this.cumulableFilter.splice(indexOfItem, 1);
                        this.cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
                        this.scoreActive(true);
                    } else {
                        this.cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
                        if (filterType === 'scoresFilter') {
                            this.scoreActive(true);
                        }
                    }
                }
            } else {
                if (isOrderAndScore && filterType === 'scoresFilter') {
                    this.cumulableFilter.splice(indexOfItem, 1);
                    this.cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
                    this.scoreActive(true);
                } else {
                    this.cumulableFilter.push({ selectedOption, filterType });
                    if (filterType === 'scoresFilter') {
                        this.scoreActive(true);
                    }
                }
            }
        } else {
            this.cumulableFilter.push({ selectedOption, filterType });
            if (filterType === 'scoresFilter') {
                this.scoreActive(true);
            }
        }
    }

    render() {
        return (
            <div className="RatingReviewsFilter">
                <div className="column">
                    <p className={'label_title'}>{i18next.t('filter-by')}</p>
                    {this.state.isRateForScore ? (
                        <select disabled>
                            {this.filters.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <select onChange={(e) => this.changeFilterOption(e, 'scoresFilter')}>
                            {this.filters.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                </div>

                <div className="column">
                    <p className={'label_title'}>{i18next.t('order-by')}</p>
                    {this.state.isScoreActive || this.state.isDateActive ? (
                        <select disabled>
                            {this.sortOrder.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <select onChange={(e) => this.changeSortOption(e, 'sortingOrder')}>
                            {this.sortOrder.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {this.state.isRateActive ? (
                        <select disabled>
                            {this.dateOrder.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <select onChange={(e) => this.changeSortOption(e, 'sortingDateOrder')}>
                            {this.dateOrder.map((filtr, index) => {
                                return (
                                    <option value={filtr[0]} key={index}>
                                        {filtr[1]}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                </div>
            </div>
        );
    }
}

export default RatingReviewsFilter;
