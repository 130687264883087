const config = require('./temp/config');

/**
 * Check is disconnected mode started
 * @returns {boolean}
 */
const isDisconnected = () => /localhost/i.test(config.sitecoreApiHost);

module.exports.isDisconnected = isDisconnected;

/**
 * Get hostname which used to access application, in disconnected mode it can be localhost or ip address
 * @returns {string} hostname
 */

module.exports.getHostname = function(hostParam) {
  let urlFinal = config.sitecoreApiHost;
  if (hostParam !== null && config.custom_domainES !== null) {
	var urls = config.custom_domainES.split('|');
	for (var item in urls) {
	    if (hostParam.indexOf(urls[item]) != -1) {
			urlFinal = config.custom_sitecoreApiHostES;
	    }
	}    
  }
  return isDisconnected() ? window.location.origin : urlFinal;
}
