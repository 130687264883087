import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import ProductPush from '../../utils/Common/ProductPush/index';
import './HeaderMegaMenu.scss';
import { handleDiagPeauClick, handleNavigationClick, handleLogoClick } from '../Tagging';
//import lingettesImg from "../../assets/images/lingettes_mixa.png";

const HeaderMegaMenuQuery = gqlLoader('./HeaderMegaMenu.graphql');
const BREAKPOINT = 1024;

class HeaderMegaMenu extends ReactComponent {
    state = {
        isMobile: false,
    };

    componentDidMount() {
        this.handleIfMobile();
        window.addEventListener('resize', () => {
            this.handleIfMobile();
        });
    }

    componentDidUpdate(prevProps) {
        // Close mobile menu on page change
        if (prevProps.sitecoreContext.route.displayName !== this.props.sitecoreContext.route.displayName && this.state.isMobile) {
            this.handleCloseMobile();
        }
    }

    handleOpen(e) {
        if (e.currentTarget.querySelector('.secondLevel')) {
            document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
            e.currentTarget.classList.add('active');
            document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
            e.currentTarget.querySelector('.secondLevel').classList.remove('closed');
        } else {
            document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
            document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
        }
    }

    handleClose() {
        document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
        document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
    }

    // Mobile code

    handleIfMobile() {
        if (window.innerWidth < BREAKPOINT) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }

    handleToggleMobile(e) {
        document.querySelector('.navIcon').classList.toggle('open');
        document.querySelector('.navContainerMobile').classList.toggle('open');
        document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
        e.stopPropagation();
    }

    handleCloseMobile() {
        document.querySelector('.navIcon').classList.remove('open');
        document.querySelector('.navContainerMobile').classList.remove('open');
        document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
    }

    handleToggleUnfold(e) {
        e.currentTarget.classList.toggle('folded');
        e.currentTarget.querySelector('.linksList').classList.toggle('folded');
        e.stopPropagation();
    }

    handleBackToFirstLevel(e) {
        e.currentTarget.parentElement.parentElement.classList.add('closed');
        e.stopPropagation();
    }

    //POPIN MANAGEMENT
    handleClosePopin() {
        document.querySelector('.popin').classList.remove('open');
    }

    render() {
        return (
            <div className="HeaderMegaMenu">
                <Query
                    query={HeaderMegaMenuQuery}
                    variables={{
                        indexName: this.indexName,
                        rootPath: 'sitecore/content/' + this.appName + '/',
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <p>... Chargement ...</p>;
                        if (error) return <p>Error: {error.message}</p>;
                        else {
                            const menuData = data.headerMegaMenu.results.items[0];

                            //DESKTOP
                            if (!this.state.isMobile) {
                                return (
                                    <nav className="desktop">
                                        <div className="wrapLeft">
                                            <div className="logoContainer">
                                                {' '}
                                                {/* left part with logo */}
                                                <NavLinkAdv onClick={() => handleLogoClick()} to="/">
                                                    <Image loading="lazy" field={menuData && menuData.item.logo} />
                                                </NavLinkAdv>
                                            </div>
                                            <div className="navContainer" onMouseLeave={this.handleClose}>
                                                {' '}
                                                {/* Central part with nav */}
                                                <ul>
                                                    {menuData.item.children &&
                                                        menuData.item.children.map((menuItem, index) => {
                                                            return (
                                                                <li
                                                                    tabIndex="0"
                                                                    className="firstLevel"
                                                                    key={index}
                                                                    onMouseOver={(e) => this.handleOpen(e)}
                                                                    onFocus={(e) => this.handleOpen(e)}
                                                                >
                                                                    <NavLinkAdv
                                                                        to={menuItem.categoryLink.url}
                                                                        onClick={() => handleNavigationClick('header', menuItem.categoryLabel)}
                                                                    >
                                                                        <Text className="span" tag="span" field={menuItem.categoryLabel} />
                                                                    </NavLinkAdv>

                                                                    {/* ouverture megamenu */}
                                                                    {menuItem.children.length !== 0 && (
                                                                        <div className="closed secondLevel">
                                                                            {/** The product push */}
                                                                            {menuItem.productPush.targetItem && (
                                                                                <span onClick={this.handleClose}>
                                                                                    <ProductPush
                                                                                        image={
                                                                                            menuItem.productPush.targetItem &&
                                                                                            menuItem.productPush.targetItem.image
                                                                                        }
                                                                                        title={
                                                                                            menuItem.productPush.targetItem &&
                                                                                            menuItem.productPush.targetItem.title
                                                                                        }
                                                                                        url={
                                                                                            menuItem.productPush.targetItem &&
                                                                                            menuItem.productPush.targetItem.url
                                                                                        }
                                                                                        label={menuItem.discoverLabel && menuItem.discoverLabel.value}
                                                                                        codeEan={menuItem.productPush.targetItem.eanCode}
                                                                                    />
                                                                                </span>
                                                                            )}

                                                                            {menuItem.children &&
                                                                                menuItem.children.map((itemColumn, index) => (
                                                                                    <div
                                                                                        className={
                                                                                            index >= 1 ? 'withBorder itemColumns' : 'itemColumns'
                                                                                        }
                                                                                        key={index}
                                                                                    >
                                                                                        <Text tag="h4" field={itemColumn.categoryFilterLabel} />
                                                                                        {itemColumn.children.map((itemLink, index) => (
                                                                                            <div
                                                                                                key={index}
                                                                                                className="linkContainer"
                                                                                                onClick={this.handleClose}
                                                                                            >
                                                                                                <NavLinkAdv
                                                                                                    onClick={() =>
                                                                                                        handleNavigationClick(
                                                                                                            'header',
                                                                                                            menuItem.categoryLabel.value +
                                                                                                                '::' +
                                                                                                                itemLink.subCategoryLabel.value
                                                                                                        )
                                                                                                    }
                                                                                                    to={itemLink.subCategoryLink.url}
                                                                                                >
                                                                                                    {itemLink.subCategoryLabel.value}
                                                                                                </NavLinkAdv>
                                                                                            </div>
                                                                                        ))}
                                                                                        {index === 0 && (
                                                                                            // button of the 1rst column
                                                                                            <span className="btn-ctn" onClick={this.handleClose}>
                                                                                                <NavLinkAdv
                                                                                                    className="btn"
                                                                                                    to={menuItem.categoryLink.url}
                                                                                                >
                                                                                                    {menuItem.categoryButtonLabel.value}
                                                                                                </NavLinkAdv>
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="wrapRight">
                                            {' '}
                                            {/* Right part with button & search icon */}
                                            {/* <NavLinkAdv
                                                onClick={() =>
                                                    handleDiagPeauClick(
                                                        this.props.sitecoreContext,
                                                        'header',
                                                        menuData.item.buttonLabel.value,
                                                        menuData.item.buttonLink.url
                                                    )
                                                }
                                                to={menuData.item && menuData.item.buttonLink.url}
                                                className="btn diagBtn"
                                            >
                                                {menuData.item && menuData.item.buttonLabel.value}
                                            </NavLinkAdv> */}
                                            <NavLinkAdv to={menuData && menuData.item.logoLink.url}>
                                                <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" style={{ marginLeft: 20 }}>
                                                    <g id="IconSearch-Line">
                                                        <path
                                                            d="M13.973 25.737C7.481 25.737 2.217 20.472 2.217 13.978C2.217 7.483 7.481 2.218 13.973 2.218C20.466 2.218 25.73 7.483 25.73 13.978C25.73 20.472 20.466 25.737 13.973 25.737M35.564 33.862L24.702 22.929C26.726 20.504 27.947 17.384 27.947 13.978C27.947 6.258 21.691 0 13.973 0C6.256 0 0 6.258 0 13.978C0 21.697 6.256 27.955 13.973 27.955C17.48 27.955 20.676 26.654 23.129 24.52L33.983 35.444C34.419 35.881 35.127 35.881 35.564 35.444C36 35.007 36 34.299 35.564 33.862"
                                                            transform="translate(2 1.9998)"
                                                            id="Fill-1"
                                                            fill="#0057b4"
                                                            fillRule="evenodd"
                                                            stroke="none"
                                                        />
                                                    </g>
                                                </svg>
                                            </NavLinkAdv>
                                        </div>
                                    </nav>
                                );
                            }

                            // MOBILE
                            if (this.state.isMobile) {
                                return (
                                    <nav className="mobile">
                                        {/* burger icon */}
                                        <div className="head">
                                            <div className="wrapLeft">
                                                <div className="navIcon" onClick={this.handleToggleMobile}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div className="logoContainer">
                                                {' '}
                                                {/* center part with logo */}
                                                <NavLinkAdv onClick={() => handleLogoClick()} to="/">
                                                    <Image loading="lazy" field={menuData && menuData.item.logo} />
                                                </NavLinkAdv>
                                            </div>
                                            <div className="wrapRight">
                                                {' '}
                                                {/* Right part with search icon */}
                                                <NavLinkAdv to={menuData && menuData.item.logoLink.url}>
                                                    <svg
                                                        onClick={(e) => this.handleToggleMobile(e)}
                                                        className="svg-icon"
                                                        viewBox="0 0 20 20"
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            marginLeft: 20,
                                                        }}
                                                    >
                                                        <path d="M18.125,15.804l-4.038-4.037c0.675-1.079,1.012-2.308,1.01-3.534C15.089,4.62,12.199,1.75,8.584,1.75C4.815,1.75,1.982,4.726,2,8.286c0.021,3.577,2.908,6.549,6.578,6.549c1.241,0,2.417-0.347,3.44-0.985l4.032,4.026c0.167,0.166,0.43,0.166,0.596,0l1.479-1.478C18.292,16.234,18.292,15.968,18.125,15.804 M8.578,13.99c-3.198,0-5.716-2.593-5.733-5.71c-0.017-3.084,2.438-5.686,5.74-5.686c3.197,0,5.625,2.493,5.64,5.624C14.242,11.548,11.621,13.99,8.578,13.99 M16.349,16.981l-3.637-3.635c0.131-0.11,0.721-0.695,0.876-0.884l3.642,3.639L16.349,16.981z"></path>
                                                    </svg>
                                                </NavLinkAdv>
                                            </div>
                                        </div>
                                        <div className="navContainerMobile">
                                            {' '}
                                            {/* Nav mobile */}
                                            <ul>
                                                {menuData.item.children &&
                                                    menuData.item.children.map((menuItem, index) => {
                                                        return (
                                                            <li className="firstLevel" key={index} onClick={(e) => this.handleOpen(e)}>
                                                                {menuItem.children.length !== 0 ? (
                                                                    <Text field={menuItem.categoryLabel} />
                                                                ) : (
                                                                    <NavLinkAdv
                                                                        onClick={() => handleNavigationClick('header', menuItem.categoryLabel.value)}
                                                                        to={menuItem.categoryLink.url}
                                                                    >
                                                                        <Text field={menuItem.categoryLabel} />
                                                                    </NavLinkAdv>
                                                                )}
                                                                {/* ouverture megamenu */}
                                                                {menuItem.children.length !== 0 && (
                                                                    <div className="closed secondLevel">
                                                                        {/** The product push */}
                                                                        <div className="headerSecondLevel">
                                                                            <div
                                                                                className="iconBack"
                                                                                onClick={(e) => this.handleBackToFirstLevel(e)}
                                                                            ></div>
                                                                            <p>
                                                                                <Text field={menuItem.categoryLabel} />
                                                                            </p>
                                                                            <div
                                                                                className="iconClose"
                                                                                onClick={(e) => this.handleToggleMobile(e)}
                                                                            ></div>
                                                                        </div>
                                                                        {menuItem.children &&
                                                                            menuItem.children.map((itemColumn, index) => (
                                                                                <div
                                                                                    className="itemColumnsMobile folded"
                                                                                    key={index}
                                                                                    onClick={(e) => this.handleToggleUnfold(e)}
                                                                                >
                                                                                    <Text tag="h4" field={itemColumn.categoryFilterLabel} />
                                                                                    <div className="linksList folded">
                                                                                        {itemColumn.children.map((itemLink, index) => (
                                                                                            <div key={index} className="linkContainer">
                                                                                                <NavLinkAdv
                                                                                                    onClick={() =>
                                                                                                        handleNavigationClick(
                                                                                                            'header',
                                                                                                            menuItem.categoryLabel.value +
                                                                                                                '::' +
                                                                                                                itemLink.subCategoryLabel.value
                                                                                                        )
                                                                                                    }
                                                                                                    to={itemLink.subCategoryLink.url}
                                                                                                >
                                                                                                    {itemLink.subCategoryLabel.value}
                                                                                                </NavLinkAdv>
                                                                                            </div>
                                                                                        ))}
                                                                                        {index === 0 && (
                                                                                            // button of the 1rst column
                                                                                            <span>
                                                                                                <NavLinkAdv
                                                                                                    className="btn"
                                                                                                    to={menuItem.categoryLink.url}
                                                                                                >
                                                                                                    {menuItem.categoryButtonLabel.value}
                                                                                                </NavLinkAdv>
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        <span>
                                                                            <ProductPush
                                                                                image={
                                                                                    menuItem.productPush.targetItem &&
                                                                                    menuItem.productPush.targetItem.image
                                                                                }
                                                                                title={
                                                                                    menuItem.productPush.targetItem &&
                                                                                    menuItem.productPush.targetItem.title
                                                                                }
                                                                                url={
                                                                                    menuItem.productPush.targetItem &&
                                                                                    menuItem.productPush.targetItem.url
                                                                                }
                                                                                label={menuItem.discoverLabel && menuItem.discoverLabel.value}
                                                                                codeEan={menuItem.productPush.targetItem.eanCode}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                            <span onClick={(e) => this.handleToggleMobile(e)}>
                                                <NavLinkAdv
                                                    onClick={() =>
                                                        handleDiagPeauClick(
                                                            this.props.sitecoreContext,
                                                            'header',
                                                            menuData.item.buttonLabel.value,
                                                            menuData.item.buttonLink.url
                                                        )
                                                    }
                                                    className="btn diagBtn"
                                                    to={menuData.item && menuData.item.buttonLink.url}
                                                >
                                                    {menuData.item && menuData.item.buttonLabel.value}
                                                </NavLinkAdv>
                                            </span>
                                        </div>
                                    </nav>
                                );
                            }
                        }
                    }}
                </Query>
                {/* <div className="popin open">
        <div className="popin-close" aria-label="Close" onClick={this.handleClosePopin}>
          <svg width="30" height="30" viewBox="0 0 44 44" aria-hidden="true" focusable="false">
          <path d="M0.549989 4.44999L4.44999 0.549988L43.45 39.55L39.55 43.45L0.549989 4.44999Z" fill="#0057b4"/>
          <path d="M39.55 0.549988L43.45 4.44999L4.44999 43.45L0.549988 39.55L39.55 0.549988Z" fill="#0057b4"/>
          </svg>
        </div>
        <h2>INFORMATION</h2>
        <img src={lingettesImg} alt="illustration lingettes"/>
        <p>
        Chez Mixa la sécurité de nos consommateurs et la qualité de nos produits sont des priorités absolues. Nous ne faisons aucun compromis en la matière. Dans le cadre de nos contrôles qualité, nous avons constaté la présence de bactéries dans certains échantillons de lingettes à l’eau nettoyante et de lingettes à l’eau nettoyante physiologique de la marque Mixa Bébé [code-barres : 3 058 325 066 246; 3 058 325 683 399; 3 600 550 313 475; 3 600 551 033 808; 3 600 551 033 563]. Par précaution, nous procédons au rappel de ces produits. Cette bactérie, très fréquente dans l’environnement, est susceptible de provoquer dans des cas exceptionnels, des infections chez certaines personnes telles que les sujets immunodéprimés ou atteints de mucoviscidose. Ce produit ne présente pas de risque pour la peau des personnes en bonne santé (système immunitaire normal) y compris celle des bébés.
        </p>
        <p>
        Nous n’avons à ce jour enregistré aucun effet indésirable en rapport avec ce problème qualité. Nous demandons aux personnes en possession de ces produits de ne pas les utiliser et de le rapporter en magasin en vue d’un remboursement. Pour toute information, vous pouvez contacter le numéro vert <b>0 800 111 777</b> (gratuit 7 jours sur 7 entre 8h et 20h).
        </p>
      </div> */}
            </div>
        );
    }
}
export default withSitecoreContext()(HeaderMegaMenu);
